<template>
	<div class="feed" v-if="JSON.stringify(feedbackinfo) != '{}'">
		<!-- 导航栏 -->
		<van-nav-bar class="nav_bar_color" title="试乘试驾反馈" left-text="返回" left-arrow @click-left="onClickLeft" fixed
			z-index="11" :border="false" placeholder>
		</van-nav-bar>
		<!-- 头部 -->
		<div class="head">
			<div class="feed_concat">
				<div class="feed_concat_name">
					尊敬的{{feedbackinfo.name}}：
				</div>
				
				<div class="feed_concat_content">
					&nbsp;&nbsp;感谢您在{{getDateTime2(feedbackinfo.starttime).slice(5)}}参与了凯迪拉克{{feedbackinfo.model_name}}的试乘试驾体验，在本次体验中，您定制了{{feedbackinfo.score}}项专属试乘试驾体验项目，其中印象最为深刻与需要改善的项目体验是
				</div>
				
				<div class="feed_project">
					体验项目
				</div>
				<div class="feed_experience">
					<div class="feed_left">
						<span>最吸引您的车辆</span>
						<span>体验是哪些</span>
						<span>（最多选3项）</span>
					</div>
					<div class="feed_right">
						<span>是否有需要改善</span>
						<span>的车辆体验</span>
						<span>（最多选3项）</span>
					</div>
				</div>
				
				<div class="feed_list" v-for="v,index in feedbackinfo.feedback" :key="index">
					<div class="feed_list_title">
						{{index+1}},{{v.content}}
					</div>
					<div class="feed_list_img">
						<img @click="upattract(index,0)" v-if="form.attract_feedback[index] == 1" src="@/assets/drivroute/icon_jxxz.png" mode="">
						<img @click="upattract(index,1)" v-else src="@/assets/drivroute/icon_jxwxz.png" mode="">
						<img @click="upbetter(index,0)" v-if="form.better_feedback[index] == 2" src="@/assets/drivroute/icon_jxxz.png" mode="">
						<img @click="upbetter(index,2)" v-else src="@/assets/drivroute/icon_jxwxz.png" mode="">
					</div>
				</div>
				
				<div class="feed_detail">
					<div class="">
						在本次试乘试驾体验中，怠速下，噪音值为{{feedbackinfo.idling_noise}}分贝，相当于身处{{getNoiseValue(feedbackinfo.idling_noise)}}环境；60KM/H速度时，隔音降噪下降数值为：{{feedbackinfo.down_noise}}分贝。
					</div>
					<div class="">
						30分之内，空气净化效果为降低{{feedbackinfo.clean_air - feedbackinfo.down_air}}，达到{{feedbackinfo.down_air}}数值，相当于从{{getNoiseValue(feedbackinfo.clean_air - feedbackinfo.down_air)}}环境到达{{getNoiseValue(feedbackinfo.down_air)}}环境。
					</div>
				</div>
				
				<div class="feed_Score">
					我的评分
				</div>
				<div class="feed_Score_name">
					我对本次试乘试驾的评价
				</div>
				<van-rate
					v-model="rateValue"
					:size="40"
					color="#ffd21e"
					void-icon="star"
					void-color="#333333"
					@change="onChange"
				/>
				
				<div class="feed_line">
					
				</div>
				
				<div class="feed_recommend">
					<div class="feed_recommend_title">
						是否愿意向朋友推荐凯迪拉克的产品
					</div>
					<div class="feed_recommend_list">
						<div @click="upcolor(1)" :class="{'active-color':form.if_recommend == 1}" class="feed_recommend_Single">
							非常乐意
						</div>
						<div @click="upcolor(2)" :class="{'active-color':form.if_recommend == 2}" class="feed_recommend_Single">
							看情况
						</div>
						<div @click="upcolor(3)" :class="{'active-color':form.if_recommend == 3}" class="feed_recommend_Single">
							再说吧
						</div>
						<div @click="upcolor(4)" :class="{'active-color':form.if_recommend == 4}" class="feed_recommend_Single">
							不愿意
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="user_footer">
			<div class="" @click="save">
				提交反馈单
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				feedbackinfo:{},
				rateValue:0,
				form:{
					number:'CH240623010401311',
					score:'',
					if_recommend:'',
					feedback_content_ids:[],
					attract_feedback:[],
					better_feedback:[],
					store_id:0,
					impressive_feedback:'',
					improve_feedback:'',
				}
			}
		},
		mounted(){
			this.form.number = this.$route.query.number
			this.getfeedbackinfo()
		},
		methods:{
			// 时间格式化
			getDateTime2(timestamp) {
				const date = new Date(timestamp * 1000)
				const Y = date.getFullYear() + '年'
				const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
				const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
				return Y + M + D
			},
			async save(){
				if(!(this.form.attract_feedback.some(item => item > 0))) return this.$toast.success('请选择体验项目','error')
				if(!(this.form.better_feedback.some(item => item > 0))) return this.$toast.success('请选择体验项目','error')
				if(this.form.score == '') return this.$toast.success('请选择评分','error')
				if(this.form.if_recommend == '') return this.$toast.success('请选择推荐意愿','error')
				this.form.score = this.form.score*2
				this.form.feedback_content_ids = this.form.feedback_content_ids.join(',')
				this.form.attract_feedback = this.form.attract_feedback.join(',')
				this.form.better_feedback = this.form.better_feedback.join(',')
				const {data:res} = await this.$http.post('/api/scsj/edit_feedback_report_1_7',this.form)
				if(res.code == 1){
					this.$toast.success('反馈成功')
					this.$router.replace({ // 跳转到tabbar
						path: '/share/drive-report-share?number='+ this.form.number
					})
				}else{
					this.$toast.fail(res.msg)
				}
			},
			upattract(index,e){
				if(e == 1){
					let itemlist = []
					this.form.attract_feedback.map(item => {
						 if(item == 1){
							 itemlist.push(item)
						 }
					})
					if(itemlist.length == 3) return this.$toast.success('反馈最多为3个','error')
				}
				this.$set(this.form.attract_feedback,index,e)
			},
			upbetter(index,e){
				if(e == 2){
					let itemlist = []
					this.form.better_feedback.map(item => {
						if(item == 2){
							itemlist.push(item)
						}
					})
					if(itemlist.length == 3) return this.$toast.success('反馈最多为3个','error')
				}
				this.$set(this.form.better_feedback,index,e)
			},
			// 满意选择
			upcolor(e){
				this.form.if_recommend = e
			},
			// 评分
			onChange(e){
				this.form.score = e
			},
			// 噪音
			getNoiseValue(value){
				 let str = ''
				 switch(true){
					case value < 20:
					str = '实验室';
					break;
					case value >= 20 && value < 30:
					str = '1000米深海';
					break;
					case value >= 30 && value < 35:
					str = '漫天飞雪之中';
					break;
					case value >= 35 && value < 40:
					str = '安静的图书馆';
					break;
					case value >= 40 && value < 45:
					str = '一个人的安静房间';
					break;
					case value >= 45 && value < 60:
					str = '户外公园';
					break;
					case value >= 60 && value < 80:
					str = '室外马路边';
					break;
					case value >= 80 && value < 90:
					str = '喧嚣的十字路口';
					break;
					case value >= 90 && value < 100:
					str = '鸣笛的大货车旁';
					break;
					case value >= 100 && value < 120:
					str = 'F1赛道';
					break;
					case value >= 120 && value <= 150:
					str = '喷气式飞机起飞';
					break;
					case value > 150:
					str = '火箭发射场';
					break;
					default:
				 console.log(value,'噪音环境值错误');
				 }
				 return str;
			},
			// 空气
			getAirScene(value){
				let txt = ''
				switch(true){
					case value <= 20:
						txt = '青藏高原阳光之城-日喀则';
						break;
					case value > 20 && value <= 35:
						txt = '云南香格里拉普达措';
						break;
					case value > 35 && value <= 50:
						txt = '海南三亚海滩';
						break;
					case value > 50 && value <= 100:
						txt = '良好';
						break;
					case value > 100 && value <= 150:
						txt = '一般';
						break;
					case value > 150 && value <= 200:
						txt = '中度污染';
						break;
					case value > 200 && value <= 300:
						txt = '重度污染';
						break;
					case value > 300 && value <= 500:
						txt = '无法呼吸';
						break;
					case value > 500:
						txt = '地狱';
						break;
					default:
						console.log(value, '获取初空气环境错误');
				}
				return txt
			},
			// 获取详情
			async getfeedbackinfo(){
				const {data:res} = await this.$http.post('/api/scsj/get_feedback_report_1_7',{number:this.form.number})
				if(res.code == 1){
					if(res.data.res.if_recommend == 1){
						return this.$router.replace({ // 跳转到tabbar
							path: '/share/feedback-share-super?number='+ this.form.number
						})
					}
					if(res.data.res.if_recommend != null){
						return this.$router.replace({ // 跳转到tabbar
							path: '/share/drive-report-share?number='+ this.form.number
						})
					}
					this.feedbackinfo = res.data.res
					this.form.feedback_content_ids = res.data.res.feedback.map(item => item.id)
					this.form.attract_feedback = res.data.res.feedback.map(item => 0)
					this.form.better_feedback = res.data.res.feedback.map(item => 0)
					this.form.impressive_feedback = res.data.res.points.map(item => 0).join(',')
					this.form.improve_feedback = this.form.impressive_feedback
				}
			},
		}
		
		}
</script>

<style lang="scss" scoped>
.feed_concat{
	padding: 40px;
	box-sizing: border-box;
	.feed_concat_name{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 36px;
	}
	.feed_concat_content{
		padding: 30px 0;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #E7E7E7;
		line-height: 48px;
	}
	.feed_project{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		margin-top: 20px;
	}
	.feed_experience{
		padding: 32px 0;
		margin-bottom: 40px;
		border-bottom: 1px solid #333333;
		display: flex;
		justify-content: space-between;
		div{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		span{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #A1A1A1;
			line-height: 32px;
		}
	}
	.feed_list{
		.feed_list_title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 48px;
		}
		.feed_list_img{
			padding: 30px 50px 50px;
			display: flex;
			justify-content: space-between;
			img{
				width: 144px;
				height: 72px;
			}
		}
	}
	.feed_detail{
		padding: 32px 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		background: #222222;
		font-weight: normal;
		color: #C8C8C8;
		line-height: 48px;
		div:nth-child(2){
			margin-top: 48px;
		}
	}
	.feed_Score{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		margin-top: 60px;
	}
	.feed_Score_name{
		padding: 40px 0;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #A1A1A1;
	}
	.feed_line{
		margin-top: 25px;
		height: 1px;
		background: #333333;
	}
	.feed_recommend{
		padding: 48px 0;
		.feed_recommend_title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 32px;
		}
		.feed_recommend_list{
			margin-top: 48px;
			display: flex;
			justify-content: space-between;
			.feed_recommend_Single{
				width: 148px;
				height: 100px;
				background: #333333;
				text-align: center;
				line-height: 100px;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
	}
}

.user_footer{
	height: 132px;
	background: #000000;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	div{
		width: 670px;
		box-sizing: border-box;
		height: 92px;
		background: #FA0037;
		text-align: center;
		margin: 0 auto 0;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}


.active-color{
	background-color: #f3c845 !important;
}
</style>